import moment from 'moment';
export default {
  formatDate,
  formatDateOnly,
  formatPrice,
  formatDateFull,
  formatBirthDate
};

function formatDate(date) {
  return moment(date).format('DD/MM/YY HH:mm');
}
function formatDateOnly(date) {
  return moment(date).format('DD/MM/YY');
}
function formatDateFull(date) {
  return moment(date).format('YYYY-DD-MM HH:mm:ss');
}
function formatPrice(price) {
  const parts = Number(price).toFixed(2).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  if (parts[1] && parts[1] === '00') return parts[0];
  return parts.join('.');
}
function formatBirthDate(date) {
  if (!date) {
    return 'Отсутствует';
  }
  return moment(date).format('DD.MM.YYYY');
}
