<template>
  <div>
    <NavBar />
    <v-row class="mx-0 mt-2 grey lighten-3 mb-0">
      <v-col md="4" class="d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text disabled v-bind="attrs" v-on="on">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Выгрузить в Excel</span>
        </v-tooltip>
        <v-menu
          ref="dateRangePicker"
          v-model="isDateRangePickerVisible"
          :close-on-content-click="false"
          :return-value.sync="dateRange"
          transition="slide-y-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              class="ml-3"
              prepend-inner-icon="mdi-calendar-range"
              hide-details
              readonly
              flat
              solo
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="datePickerRange" :first-day-of-week="1" :max="`${currentDate}`" @change="onDatePickerRangeChange" no-title range scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="isDateRangePickerVisible = false">Отмена</v-btn>
            <v-btn text color="primary" @click="onDatePickerRangeSave">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="8" class="pt-0 pt-md-3">
        <v-text-field v-model="search" placeholder="Тип, сообщение" prepend-inner-icon="mdi-magnify" hide-details single-line clearable dense flat solo />
      </v-col>
    </v-row>
    <div class="ReferenceOperatorMainframe">
      <div class="ReferenceUpBoard">
        <div v-if="!this.isOrdersLoading && !this.isOrdersError && this.orders && this.orders.length > 0">
          <div class="ReferenceProcessList">
            <div class="ReferenceProcessListNumber">
              {{ this.orders.length }}
            </div>
            <div class="pt-2">
              {{ this.declOfNum(this.orders.length, ['заказ', 'заказа', 'заказов']) }}
              <v-spacer />
              всего
            </div>
          </div>
          <v-row class="px-2">
            <v-checkbox class="mr-2" v-model="waitingOperatorWork" justijy-content-end :label="'Ожидают обработки'"></v-checkbox>
            <v-checkbox class="mr-2" v-model="orderVerifyDone" justijy-content-end :label="'Заявка проверена'"></v-checkbox>
            <v-checkbox class="mr-2" v-model="orderAccept" justijy-content-end :label="'Принята рабочим'"></v-checkbox>
            <v-checkbox class="mr-2" v-model="orderReturn" justijy-content-end :label="'Возвращена рабочим'"></v-checkbox>
            <v-checkbox class="mr-2" v-model="isOrderDone" justijy-content-end :label="'Завершены'"></v-checkbox>
            <v-checkbox class="mr-2" v-model="isCancelOrder" justijy-content-end :label="'Отменены'"></v-checkbox>
          </v-row>
          <ReportList :orderList="filteredOrders" :queryParams="queryParams" />
        </div>
        <div v-else>
          <v-chip label text-color="white" color="#0066A1">
            {{ this.loadProgress }}
          </v-chip>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import NavBar from '@/components/NavBar';
import ReportList from '@/components/report/ReportList';
import helper from '@/helpers/reference/reference.helper';
//   import AdminService from '@/services/AdminService';
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';

export default {
  data() {
    const today = moment().format('YYYY-MM-DD');
    return {
      isDateRangePickerVisible: false,
      datePickerRange: [today, today],
      dateRange: [today, today],
      receiptTypeSelect: [0, 1],
      delayedPayStatusSelect: [0, 1, 2, 3],
      currentDate: today,
      search: '',
      timer: null,
      waitingOperatorWork: true,
      orderVerifyDone: true,
      orderAccept: true,
      orderReturn: false,
      isOrderDone: false,
      isCancelOrder: false,
      isWorkProgress: false,
      currentUser: JSON.parse(localStorage.getItem('user')).fio
    };
  },
  components: {
    NavBar,
    ReportList
  },
  computed: {
    filteredOrders() {
      let modifySearch = this.search.toLowerCase();
      let filtredArray = [];
      if (modifySearch !== '') {
        filtredArray = this.orders.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(modifySearch) !== -1 ||
            // item.mail.indexOf(modifySearch) !== -1 ||
            item.phone.indexOf(modifySearch) !== -1 ||
            this.getReferenceTypeName(item.referenceType).toLowerCase().indexOf(modifySearch) !== -1 ||
            item.orderNumber.indexOf(modifySearch) !== -1
          );
        });
      } else {
        filtredArray = this.orders;
        if (this.isShowCreated === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.currentState !== 1;
          });
        }
        if (this.isShowPaymentFalse === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.currentState !== 2;
          });
        }
        if (this.waitingOperatorWork === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.currentState !== 3;
          });
        }
        if (this.orderVerifyDone === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.currentState !== 4;
          });
        }
        if (this.orderAccept === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.currentState !== 5;
          });
        }
        if (this.orderReturn === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.currentState !== 6;
          });
        }
        if (this.isOrderDone === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.currentState !== 7;
          });
        }
        if (this.isCancelOrder === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.currentState !== 100;
          });
        }
        if (this.isWorkProgress === false) {
          filtredArray = filtredArray.filter((item) => {
            return this.isOrderTaken(item) === false || this.isOrderTaken(item) === this.currentUser;
          });
        }
      }
      return filtredArray;
    },
    queryParams() {
      const params = new URLSearchParams();
      params.append('from', this.datePickerRange[0]);
      params.append('until', this.datePickerRange[1]);
      return params;
    },
    dateRangeText() {
      const dateFrom = moment(this.datePickerRange[0]);
      const dateTo = moment(this.datePickerRange[1]);
      return this.formatDateRangeText(dateFrom, dateTo);
    },
    loadProgress() {
      if (this.isOrdersLoading) {
        return 'Загружаем список';
      } else if (this.isOrdersError) {
        return 'Ошибка загрузки списка: ' + this.isOrdersError;
      } else if (!this.filteredOrders || this.filteredOrders.length <= 0) {
        return 'Список пуст';
      } else {
        return 'Количество записей: ' + this.filteredOrders.length;
      }
    },
    ...mapGetters('report', ['orders']),
    ...mapGetters('report', ['isOrdersLoading']),
    ...mapGetters('report', ['isOrdersError'])
  },
  methods: {
    getReferenceTypeName: ReferenceHelper.getReferenceTypeName,
    declOfNum: helper.declOfNum,
    isOrderTaken(order) {
      if (!order || !order.reservedDate) {
        return false;
      }
      if (moment(order.reservedDate).isBefore(moment().subtract(10, 'm'))) {
        return false;
      }
      if (!order.reservedUser.fio || order.reservedUser.fio.length <= 0) {
        return '';
      }
      return order.reservedUser.fio;
    },
    onDatePickerRangeChange() {
      if (this.datePickerRange[1] < this.datePickerRange[0]) {
        this.datePickerRange.reverse();
      }
    },
    onDatePickerRangeSave() {
      if (this.datePickerRange.length === 1) {
        this.datePickerRange.push(this.currentDate);
      }
      this.$refs.dateRangePicker.save(this.datePickerRange);
      this.updateOrders();
    },
    formatDateRangeText(dateFrom, dateTo) {
      if (dateTo.diff(dateFrom) === 0) {
        return `${dateFrom.format('D MMM')}`;
      }
      let dateFromFormat, dateToFormat;
      dateFromFormat = dateToFormat = 'D MMM YYYY';
      if (dateFrom.year() === dateTo.year()) {
        if (dateFrom.year() === this.$moment(this.currentDate).year()) {
          dateToFormat = 'D MMM';
        }
        if (dateFrom.month() === dateTo.month()) {
          dateFromFormat = 'D';
        } else {
          dateFromFormat = 'D MMM';
        }
      }
      return `С ${dateFrom.format(dateFromFormat)} по ${dateTo.format(dateToFormat)}`;
    },
    //   downloadReport() {
    //     AdminService.downloadOrdersExcel(this.queryParams).then(res => {
    //       const link = document.createElement('a');
    //       link.href = window.URL.createObjectURL(new Blob([res.data]));
    //       link.setAttribute('download', `Отчет с ${this.datePickerRange[0]} по ${this.datePickerRange[1]} на ${moment().format('YYYY-MM-DD HH-mm-ss')}.xlsx`);
    //       document.body.appendChild(link);
    //       link.click();
    //     });
    //   },
    updateOrders() {
      this.fetchOrdersByDates({
        queryParams: this.queryParams
      });
    },
    updateWorkers() {
      this.fetchWorkerList();
    },
    updateStations() {
      this.fetchStationsList();
    },
    ...mapActions('report', ['fetchOrdersByDates'])
  },
  mounted() {
    this.updateOrders();
    // this.updateWorkers();
    // this.updateStations();
  }
};
</script>

<style>
.ReferenceProcessList {
  display: flex;
}
.ReferenceProcessListNumber {
  font-size: 50px;
  color: #e21a1a;
  padding: 10px;
  line-height: 1;
  font-weight: normal;
}
</style>
