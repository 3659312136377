const Stations = require('./stations.json');
const Fields = require('./fields.json');
const REFERENCE_TYPES = {
  1: 'Стоимость проезда',
  2: 'Утерянный документ',
  3: 'Наличие мест',
  4: 'Кратчайший маршрут',
  5: 'Изменение движения'
};
const TARIFF_LIST = [
  { value: 'ПОЛНЫЙ', text: 'Полный тариф' },
  { value: 'ДЕТСКИЙ', text: 'Детский тариф' },
  { value: 'ШКОЛЬНЫЙ', text: 'Школьный тариф' }
];
const CAR_LIST = [
  { value: 'ПЛАЦКАРТ', text: 'ПЛАЦКАРТ' },
  { value: 'КУПЕ', text: 'КУПЕ' },
  { value: 'ЛЮКС', text: 'ЛЮКС' },
  { value: 'СВ', text: 'СВ' },
  { value: 'ОБЩИЙ', text: 'ОБЩИЙ' },
  { value: 'СИДЯЧИЙ-БИЗНЕС', text: 'СИДЯЧИЙ-БИЗНЕС' },
  { value: 'СИДЯЧИЙ-ЭКОНОМ', text: 'СИДЯЧИЙ-ЭКОНОМ' }
];
const SEAT_LIST = [
  { value: 'БН', text: 'Без указания конкретного места' },
  { value: 'И', text: 'Места для инвалидов' }
];
const ORDER_STATUS = {
  11: 'Оплачен',
  21: 'Отправлен в ресторан',
  22: 'Принят рестораном',
  23: 'Готовится',
  24: 'Приготовлен',
  30: 'Готов к выдаче',
  31: 'Доставляется',
  40: 'Ожидает передачи клиенту',
  50: 'Завершен',
  100: 'Отменен'
};
const ORDER_STATUS_FOR_CLIENT = {
  11: 'Оплачен, передан в ресторан',
  21: 'Отправлен в ресторан',
  22: 'Принят рестораном',
  23: 'Готовится',
  24: 'Приготовлен',
  30: 'Готов к выдаче',
  31: 'Доставляется',
  40: 'Ожидает передачи клиенту',
  50: 'Завершен',
  100: 'Отменен'
};
const PAYMENT_STATUS = {
  1: 'Платёж создан',
  2: 'Платёж отклонён',
  3: 'Платёж подтверждён'
};
const ACTIVE_PROPERTY = [
  { value: true, text: 'Да' },
  { value: false, text: 'Нет' }
];
const TRAIN_LIST = [
  { value: 'ВЫСОКОСКОРОСТНОЙ', text: 'ВЫСОКОСКОРОСТНОЙ' },
  { value: 'ФИРМЕННЫЙ', text: 'ФИРМЕННЫЙ' },
  { value: 'СКОРЫЙ', text: 'СКОРЫЙ' },
  { value: 'ПАССАЖИРСКИЙ', text: 'ПАССАЖИРСКИЙ' },
  { value: 'СКОРОСТНОЙ', text: 'СКОРОСТНОЙ' }
];
export default {
  getStationList,
  getTariffTypeList,
  getCarTypeList,
  getSeatTypeList,
  getReferenceTypeName,
  checkFioCharacters,
  declOfNum,
  getStationName,
  getTariffName,
  getCarName,
  getSeatName,
  getOrderStatus,
  getTrainTypeList,
  getFields,
  getTrainName,
  getOrderStatusForClient,
  getActiveProperty,
  getActiveName
};

function getReferenceTypeName(referenceType) {
  return REFERENCE_TYPES[referenceType] || 'Тип: ' + referenceType;
}
function checkFioCharacters(fio) {
  const exp = new RegExp('[^а-яА-Яa-zA-Z-ёЁ]+');
  return !exp.test(fio);
}
function declOfNum(n, text_forms) {
  n = Math.abs(n) % 100;
  let n1 = n % 10;
  if (n > 10 && n < 20) {
    return text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1];
  }
  if (n1 === 1) {
    return text_forms[0];
  }
  return text_forms[2];
}
function getStationList() {
  const stationList = [];
  for (let i = 0; i < Stations.length; i++) {
    stationList.push({ value: Stations[i].code, text: Stations[i].name });
  }
  return stationList;
}
function getFields() {
  return JSON.parse(JSON.stringify(Fields));
}
function getTariffTypeList() {
  return TARIFF_LIST;
}
function getCarTypeList() {
  return CAR_LIST;
}
function getSeatTypeList() {
  return SEAT_LIST;
}
function getTrainTypeList() {
  return TRAIN_LIST;
}
function getActiveProperty() {
  return ACTIVE_PROPERTY;
}
function getActiveName(value) {
  return getTextByValue(ACTIVE_PROPERTY, value);
}
function getOrderStatus(value) {
  return ORDER_STATUS[value] || 'Статус: ' + value;
}
function getOrderStatusForClient(value) {
  return ORDER_STATUS_FOR_CLIENT[value] || 'Статус: ' + value;
}
function getStationName(value) {
  return getTextByValue(getStationList(), value);
}
function getTariffName(value) {
  return getTextByValue(TARIFF_LIST, value);
}
function getCarName(value) {
  return getTextByValue(CAR_LIST, value);
}
function getSeatName(value) {
  return getTextByValue(SEAT_LIST, value);
}
function getTrainName(value) {
  return getTextByValue(TRAIN_LIST, value);
}

function getTextByValue(list, value) {
  let name = value;
  for (let i = 0; i < list.length; i++) {
    if (list[i].value === value) {
      name = list[i].text;
      break;
    }
  }
  return name;
}
