<template>
  <div>
    <v-btn color="#e21a1a" v-on:click="getOrder" depressed :loading="this.isOrderLoading">
      <v-icon color="white" left>mdi-arrow-right-bottom</v-icon>
      <span class="ReferenceOperatorWorkButtonText">Открыть заявку</span>
    </v-btn>
    <v-dialog v-model="dialog" max-width="1280" persistent>
      <v-card v-if="this.isShowWindow" class="file-upload-card" outlined>
        <v-form ref="productForm" @submit.prevent="checkInput">
          <v-card-title class="pb-4">{{ this.getServiceName }}, № {{ this.orderById.orderNumber }}</v-card-title>
          <v-container class="OperatorWorkContainer">
            <v-row no-gutters class="RowField">
              <!-- <v-col> -->
              <v-col>
                <v-card-title class="VCardTitle">Фамилия И. О.</v-card-title>
                <v-text-field class="custom-label-color" readonly outlined :label="orderById.name" v-model="orderById.modifyName" dense required></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Номер телефона</v-card-title>
                <v-text-field
                  class="custom-label-color"
                  readonly
                  outlined
                  :label="orderById.phone"
                  v-model="orderById.modifyPhone"
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Вокзал</v-card-title>
                <v-text-field class="custom-label-color" dense required outlined readonly v-model="computedStationName"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Номер поезда</v-card-title>
                <v-text-field
                  dense
                  required
                  class="custom-label-color"
                  readonly
                  outlined
                  :label="orderById.train"
                  v-model="orderById.modifyTrain"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Номер вагона</v-card-title>
                <v-text-field class="custom-label-color" dense required :label="computedCar" readonly outlined v-model="orderById.modifyCar"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Номер места</v-card-title>
                <v-text-field dense required class="custom-label-color" :label="computedSeat" readonly outlined v-model="orderById.modifySeat"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Тип тарифа</v-card-title>
                <v-text-field dense required readonly class="custom-label-color" outlined :label="tariffName" v-model="modifyTariffName"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Колличество ручной клади</v-card-title>
                <v-text-field dense required class="custom-label-color" outlined readonly :label="luggageName" v-model="modifyLuggageName"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Дата начала</v-card-title>
                <v-text-field dense required readonly class="custom-label-color" outlined :label="dateFrom" v-model="modifyDateFrom" />
              </v-col>
            </v-row>

            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Дата окончания</v-card-title>
                <v-text-field dense required readonly class="custom-label-color" outlined :label="dateTo" v-model="modifyDateTo" />
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Тип оплаты</v-card-title>
                <v-text-field dense required readonly class="custom-label-color" outlined v-model="paymentName"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Комментарий</v-card-title>
                <v-textarea
                  dense
                  required
                  class="custom-label-color"
                  outlined
                  readonly
                  :label="orderById.comment"
                  v-model="orderById.modifyComment"
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField">
              <v-col>
                <v-card-title class="VCardTitle">Назначенный рабочий</v-card-title>
                <v-text-field outlined dense required v-model="computedWorkerName" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField" v-if="this.orderById.returnMessage !== null && this.orderById.returnMessage.length > 0">
              <v-col>
                <v-card-title class="VCardTitle">Причина возврата</v-card-title>
                <v-textarea dense required class="custom-label-color" disabled outlined v-model="orderById.returnMessage" rows="1"></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters class="RowField" v-if="this.orderById.cancelMessage !== null && this.orderById.cancelMessage.length > 0">
              <v-col>
                <v-card-title class="VCardTitle">Причина отмены</v-card-title>
                <v-textarea dense required class="custom-label-color" disabled outlined v-model="orderById.cancelMessage" rows="1"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="mb-2 d-flex">
            <v-spacer></v-spacer>
            <v-btn text class="mx-2 text-none my-1" color="grey darken-3" @click="dialog = false">Закрыть</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ReportHelper from '@/helpers/reference/reference.helper';
import moment from 'moment';
export default {
  props: {
    order: {
      required: true
    }
  },
  data() {
    return {
      isOrderLoading: false,
      isShowWindow: false,
      dialog: false,
      orderData: null
    };
  },
  computed: {
    computedStationName() {
      if (
        this.orderById === undefined ||
        this.orderById === null ||
        this.orderById.stationId === null ||
        this.orderById.stationId === undefined ||
        this.stationsList === undefined ||
        this.stationsList === null
      )
        return '';
      return this.stationsList.stations.find((station) => station.id === this.orderById.stationId).name;
    },
    computedWorkerName() {
      if (
        this.orderById === undefined ||
        this.orderById === null ||
        this.orderById.workerId === null ||
        this.orderById.workerId === undefined ||
        this.workerList === undefined ||
        this.workerList === null
      )
        return '';
      return this.workerList.workers.find((worker) => worker.id === this.orderById.workerId).fio;
    },
    computedCar() {
      if (this.orderById !== undefined && this.orderById !== null && this.orderById.car !== null && this.orderById.car !== undefined) {
        return this.orderById.car.toString();
      }
      return '';
    },
    computedSeat() {
      if (this.orderById !== undefined && this.orderById !== null && this.orderById.seat !== null && this.orderById.seat !== undefined) {
        return this.orderById.seat.toString();
      }
      return '';
    },
    getServiceName() {
      let returnName = 'Услуга';
      if (this.orderById.orderType === 1) {
        returnName = 'Услуга cопровождения';
      } else if (this.orderById.orderType === 2) {
        returnName = 'Услуга носильщика (станционного портье)';
      }
      return returnName;
    },
    tariffName() {
      return this.getTariffName(this.orderById.tariff);
    },
    modifyTariffName() {
      return this.getTariffName(this.orderById.modifyTariff);
    },
    luggageName() {
      return this.getLuggageName(this.orderById.luggage);
    },
    modifyLuggageName() {
      return this.getLuggageName(this.orderById.modifyLuggage);
    },
    paymentName() {
      return this.getPaymentName(this.orderById.paymentType);
    },
    dateFrom() {
      return moment(this.orderById.dateFrom).format('DD/MM/YY hh:MM');
    },
    dateTo() {
      return moment(this.orderById.dateTo).format('DD/MM/YY hh:MM');
    },
    modifyDateFrom() {
      if (this.orderById.modifyDateFrom === undefined || this.orderById.modifyDateFrom === null) return '';
      return moment(this.orderById.modifyDateFrom).format('DD/MM/YY hh:MM');
    },
    modifyDateTo() {
      if (this.orderById.modifyDateTo === undefined || this.orderById.modifyDateTo === null) return '';

      return moment(this.orderById.modifyDateTo).format('DD/MM/YY hh:MM');
    },
    ...mapGetters('workers', ['workerList']),
    ...mapGetters('stations', ['stationsList']),
    ...mapGetters('report', ['orderById'])
  },
  methods: {
    getLuggageName(value) {
      return ReportHelper.getLuggageName(value);
    },
    getTariffName(value) {
      return ReportHelper.getTariffType(value);
    },

    getPaymentName(paymentType) {
      return ReportHelper.getPaymentType(paymentType);
    },
    async getOrder() {
      // try {
      this.isOrderLoading = true;
      console.log('bla');
      await this.fetchOrder(this.order.id);
      this.isShowWindow = true;
      this.dialog = true;
      // } catch {
      //   this.isShowWindow = false;
      //   this.dialog = false;
      // } finally {
      //   this.isOrderLoading = false;
      // }
    },
    ...mapActions('report', ['fetchOrder'])
  }
};
</script>
<style>
.RowField {
  padding-left: 15px;
}

.custom-label-color .v-label .theme--light.v-label {
  color: rgb(22, 216, 241);
  opacity: 1;
}

.OperatorWorkContainer > .RowField {
  /* flex: 1 1 33%; grow | shrink | basis */
  /* height: 100px; */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 30%;
}

.OperatorWorkContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.ButtonsGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}

.CancelButton {
  margin-right: auto;
}

.file-upload-card {
  font-family: RussianRail;
}

.OperatorWorkField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
}

.ReferenceOperatorWorkMain {
  font-weight: 400;
}

.ReferenceOperatorWorkButtonIcon {
  margin-right: 10px;
}

.ReferenceOperatorWorkButtonText {
  color: white;
}

.ReferenceOperatorWorkButtonCancelText {
  color: gray;
}

.ReferenceOperatorWorkFieldName {
  display: flex;
  justify-content: center;
  font-size: large;
  margin-bottom: 10px;
  padding: 0;
  color: black;
}

.ReferenceOperatorWorkBlockSpacer {
  margin-top: 20px;
}

.ReferenceOperatorWorkAlertBlock {
  margin-top: 20px;
  color: #c00000;
}

.ReferenceOperatorWorkPanelHeaderWarning {
  font-weight: bold;
  color: #ed7d31;
}

.ReferenceOperatorWorkPanelHeaderError {
  font-weight: bold;
  color: #c00000;
}

.ReferenceOperatorWorkHintText {
  color: #606060;
}

.ReferenceOperatorWorkPicker {
  border-radius: 5px;
  color: white;
  font-weight: 400;
  box-shadow: 0 2px 2px #8eaadb;
  border: solid 1px #2f5496;
  margin: 20px 0 10px 0;
  padding: 2px;
}

.ReferenceOperatorWorkButtonWrapper {
  border: solid 1px #e21a1a;
  border-radius: 6px;
  width: min-content;
}

.ReferenceOperatorWorkButtonCancelWrapper {
  border: solid 1px gray;
  border-radius: 6px;
  margin-left: 20px;
}

.ReferenceOperatorWorkFileText {
  color: black;
}

.ReferenceOperatorWorkCheckResult {
  display: flex;
  margin: 20px 0 0 0;
}

.ReferenceOperatorWorkProcessList {
  display: flex;
  margin: 0 40px 0 0;
}

.ReferenceOperatorWorkProcessListNumber {
  font-size: 42px;
  color: #2f5496;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListText {
  font-size: 14px;
  color: #8eaadb;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberTotal {
  font-size: 42px;
  color: black;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextTotal {
  font-size: 14px;
  color: #a6a6a6;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberWarning {
  font-size: 42px;
  color: #ed7d31;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextWarning {
  font-size: 14px;
  color: #f4b083;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberError {
  font-size: 42px;
  color: #c00000;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextError {
  font-size: 14px;
  color: #ff4b4b;
  margin-left: 16px;
}

.ReferenceOperatorWorkTableRowWarningFirst {
  background-color: #fbe4d5;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowWarningSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowErrorFirst {
  background-color: #ffc5c5;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowErrorSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableHeaderWarning {
  color: black;
  //font-size: small;
  font-weight: bold;
  //background-color: #8ab0d2;
  padding: 0 6px 0 6px;
  //border-radius: 5px;
  border-bottom: solid 1px #ed7d31;
}

.ReferenceOperatorWorkTableHeaderError {
  color: black;
  font-weight: bold;
  padding: 0 6px 0 6px;
  border-bottom: solid 1px #c00000;
}

.VCardTitle {
  font-size: 0.8em !important;
  margin: 0;
  padding: 0;
}

v-col {
  padding: 0;
}
</style>
