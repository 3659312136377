<template>
  <div>
    <v-expansion-panels mb="3">
      <v-expansion-panel v-for="order in this.orderList" :key="order.id">
        <v-expansion-panel-header :color="getColor(order)">
          <v-row>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Заказ №</p>
                {{ order.orderNumber }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Заказано</p>
                {{ formatDate(order.createdAt) }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Вид услуги</p>
                {{ getReferenceTypeName(order.orderType) }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Статус</p>
                {{ getStatus(order) }}
              </div>
              <div v-if="isOrderTaken(order) !== false">
                <p style="color: #e21a1a" class="pt-3">Забронирована оператором: {{ isOrderTaken(order) }}</p>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content :color="getColor(order)">
          <v-row>
            <v-col>
              <div>ФИО: {{ order.name }}</div>
            </v-col>
            <v-col>
              <div>
                Телефон:
                {{ order.phone }}
              </div>
            </v-col>
          </v-row>
          <br />
          <v-card-actions>
            <OrderDialog :order="order" />
            <ActionsDialog :order="order" :queryParams="queryParams" />
          </v-card-actions>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import EscortHelper from '@/helpers/reference/reference.helper';

import ViewHelper from '@/helpers/view.helper';
import ActionsDialog from '@/components/report/ActionsDialog';
import OrderDialog from '@/components/report/OrderDialog';

import moment from 'moment';
export default {
  props: {
    orderList: {
      type: Array,
      required: true
    },
    queryParams: {
      required: true
    }
  },
  components: {
    ActionsDialog,
    OrderDialog
  },
  computed: {
    ...mapGetters('admin', ['orders'])
  },
  methods: {
    getSeatName: ReferenceHelper.getSeatName,
    getCarName: ReferenceHelper.getCarName,
    getTariffName: ReferenceHelper.getTariffName,
    getStationName: ReferenceHelper.getStationName,
    getOrderStatus: ReferenceHelper.getOrderStatus,
    formatDate: ViewHelper.formatDate,
    formatDateOnly: ViewHelper.formatDateOnly,
    getReferenceTypeName: ReferenceHelper.getReferenceTypeName,
    getStatus(order) {
      return EscortHelper.getOrderStatus(order.currentState);
    },
    getColor(item) {
      let color = '';
      if (item.currentState === 6) color = '#00FF7F20';
      else if (this.isOrderTaken(item) === false && item.currentState === 4) color = '#FFFF0020';
      else if (this.isOrderTaken(item).length > 0 && item.currentState === 4) color = '#1E90FF20';
      else if (item.currentState === 100) color = '#F1525220';
      return color;
    },
    isOrderTaken(order) {
      if (!order || !order.reservedDate) {
        return false;
      }
      if (moment(order.reservedDate).isBefore(moment().subtract(10, 'm'))) {
        return false;
      }
      if (!order.reservedUser.fio || order.reservedUser.fio.length <= 0) {
        return '';
      }
      return order.reservedUser.fio;
    }
  }
};
</script>
